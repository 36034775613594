import { LineStyle } from 'lightweight-charts';
import useStore from '../Store/market_greeks_data_store';

const createSRSeries = (topStrikesData, chartInstance) => {
  const srLineRefs = {};

  const createSeriesConfig = (key, price, color, title) => {
    return {
      key,
      type: 'line',
      scale: 2,
      chartOptions: {
        color,
        lineWidth: 1,
        lineStyle: LineStyle.Dashed,
        axisLabelVisible: false,
        title,
      },
      price: Number(price),
    };
  };

  const mostRecentExpirationDate = Math.min(...topStrikesData.map(item => new Date(item.expiration_date).getTime()));
  const filteredStrikesData = topStrikesData.filter(item => new Date(item.expiration_date).getTime() === mostRecentExpirationDate);

  const callData = filteredStrikesData.filter(item => item.type === 'call');
  const putData = filteredStrikesData.filter(item => item.type === 'put');

const sortedCallData = callData.sort((a, b) => Number(b.open_interest) - Number(a.open_interest));
const sortedPutData = putData.sort((a, b) => Number(b.open_interest) - Number(a.open_interest));


  sortedCallData.slice(0, 3).forEach((item, index) => {
    const config = createSeriesConfig(`Call${index + 1}`, item.strike_price, 'green', `C ${index + 1}`);
    const lineSeries = chartInstance.addLineSeries(config.chartOptions);
    const lineData = [{ time: new Date().toISOString().slice(0, 10), value: config.price }];

    lineSeries.setData(lineData);
    srLineRefs[config.key] = lineSeries;

    // Store the original SR line data in the Zustand store
    useStore.getState().setOriginalSRData(config.key, {
      chartOptions: config.chartOptions,
      data: lineData,
    });
  });

  sortedPutData.slice(0, 3).forEach((item, index) => {
    const config = createSeriesConfig(`Put${index + 1}`, item.strike_price, 'red', `P ${index + 1}`);
    const lineSeries = chartInstance.addLineSeries(config.chartOptions);
    const lineData = [{ time: new Date().toISOString().slice(0, 10), value: config.price }];

    lineSeries.setData(lineData);
    srLineRefs[config.key] = lineSeries;

    // Store the original SR line data in the Zustand store
    useStore.getState().setOriginalSRData(config.key, {
      chartOptions: config.chartOptions,
      data: lineData,
    });
  });

  return srLineRefs;
};

export default createSRSeries;


// import { LineStyle } from 'lightweight-charts';
//
// const createSRSeries = (topStrikesData) => {
//   const createSeriesConfig = (key, price, color, title) => {
//     // console.log(`Creating series config for ${key} with price: ${price}, color: ${color}, title: ${title}`);
//     return {
//       key,
//       type: 'line',
//       scale: 2,
//       chartOptions: {
//         color,
//         lineWidth: 1,
//         lineStyle: LineStyle.Dashed,
//         axisLabelVisible: false,
//         title,
//       },
//       price: Number(price),  // Ensure the price is a number
//     };
//   };
//
//   // console.log('Top Strikes Data:', topStrikesData);
//
//   // Convert expiration dates to timestamps and filter down to the nearest upcoming Expiration_Date
//   const mostRecentExpirationDate = Math.min(...topStrikesData.map(item => new Date(item.expiration_date).getTime()));
//   const filteredStrikesData = topStrikesData.filter(item => new Date(item.expiration_date).getTime() === mostRecentExpirationDate);
//
//   // console.log('Most Recent Expiration Date:', new Date(mostRecentExpirationDate));
//   // console.log('Filtered Strikes Data:', filteredStrikesData);
//
//   // Separate filteredStrikesData into calls and puts
//   const callData = filteredStrikesData.filter(item => item.type === 'call');
//   const putData = filteredStrikesData.filter(item => item.type === 'put');
//
//   // console.log('Filtered Call Data:', callData);
//   // console.log('Filtered Put Data:', putData);
//
//   // Sort calls and puts by gamma in descending order
//   const sortedCallData = callData.sort((a, b) => Number(b.gamma_value) - Number(a.gamma_value));
//   const sortedPutData = putData.sort((a, b) => Number(b.gamma_value) - Number(a.gamma_value));
//
//   // console.log('Sorted Call Data by Gamma:', sortedCallData);
//   // console.log('Sorted Put Data by Gamma:', sortedPutData);
//
//   // Select the top 3 calls and puts by gamma
//   const top3CallSeries = sortedCallData.slice(0, 3).map((item, index) => {
//     // console.log(`Creating call series for Call${index + 1} with strike: ${item.strike_price}`);
//     return createSeriesConfig(`Call${index + 1}`, item.strike_price, 'green', `C ${index + 1}`);
//   });
//
//   const top3PutSeries = sortedPutData.slice(0, 3).map((item, index) => {
//     // console.log(`Creating put series for Put${index + 1} with strike: ${item.strike_price}`);
//     return createSeriesConfig(`Put${index + 1}`, item.strike_price, 'red', `P ${index + 1}`);
//   });
//
//   // Compile all series
//   const finalSeries = [
//     ...top3CallSeries,
//     ...top3PutSeries,
//   ];
//
//   console.log('Final Series:', finalSeries);
//
//   return finalSeries;
// };
//
// export default createSRSeries;












