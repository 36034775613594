import React, { useEffect, useRef } from 'react';
import CustomDataFeed from './CustomDataFeed';
import { customIndicatorsGetter } from './customIndicators';
import { customIndicatorsGetter as volatilityIndicatorGetter } from './volatility_indicator';
import { customIndicatorsGetter as srIndicatorGetter } from './SR_indicator';
import processSRData from './SR_series';
import useStore from '../Store/market_greeks_data_store';

const AdvancedMarketStateDashboard = () => {
  const spxData = useStore((state) => state.spxData);
  const spxGexData = useStore((state) => state.spxGexData);
  const vvixData = useStore((state) => state.vvixData);
  const topStrikesData = useStore((state) => state.topStrikes);
  const setOriginalSRData = useStore((state) => state.setOriginalSRData);
  const originalSRData = useStore((state) => state.originalSRData);
  const chartWidget = useRef(null);

  // Determine if the device is mobile
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (topStrikesData.length > 0) {
      const { topCallStrikes, topPutStrikes } = processSRData(topStrikesData);
      setOriginalSRData('topCallStrikes', topCallStrikes);
      setOriginalSRData('topPutStrikes', topPutStrikes);
    }
  }, [topStrikesData, setOriginalSRData]);

  useEffect(() => {
    if (
      spxData.length > 0 &&
      spxGexData.length > 0 &&
      vvixData.length > 0 &&
      Object.keys(originalSRData).length > 0
    ) {
      loadChartWithIndicators(spxGexData, vvixData, originalSRData);
    }

    return () => {
      if (chartWidget.current) {
        const chart = chartWidget.current.activeChart();
        chart.onVisibleRangeChanged().unsubscribeAll(this);
        chart.crossHairMoved().unsubscribeAll(this);
      }
    };
  }, [spxData, spxGexData, vvixData, originalSRData]);

  const loadChartWithIndicators = (spxGexData, vvixData, srLevelsData) => {
    const dataFeed = new CustomDataFeed();

    const widget = new window.TradingView.widget({
      container: 'chartContainer',
      library_path: '/charting_library/',
      datafeed: dataFeed,
      symbol: 'SPX',
      interval: '1D',
      autosize: true,
      theme: 'Dark',
      overrides: {
        'paneProperties.background': '#070707',
        'paneProperties.vertGridProperties.color': '#e9e9ea10',
        'paneProperties.horzGridProperties.color': '#e9e9ea10',
        'scalesProperties.textColor': '#FFFFFF',
      },
      disabled_features: isMobile
        ? [
            'scroll_past_end',
            'create_volume_indicator_by_default',
            'control_bar',
            'header_widget',
            'left_toolbar',
            'timeframes_toolbar',
            'edit_buttons_in_legend',
            'context_menus',
            'control_bar',
            'border_around_the_chart',
          ]
        : ['scroll_past_end', 'create_volume_indicator_by_default'],
      custom_indicators_getter: async (PineJS) => {
        const customIndicators = await customIndicatorsGetter(PineJS);
        const volatilityIndicators = await volatilityIndicatorGetter(PineJS);
        const srIndicators = await srIndicatorGetter(PineJS);
        return [...customIndicators, ...volatilityIndicators, ...srIndicators];
      },
    });

    widget.onChartReady(() => {
      const chart = widget.activeChart();

      chart.createStudy('SPX Market GEX', false, false, {
        offset: 0,
        source: 'spxGexData',
      });

      chart.createStudy('SPX Gamma Volatility Indicator', false, false, {
        offset: 0,
        source: 'vvixData',
      });

      chart.createStudy('SR Levels Indicator', false, false, {
        source: 'originalSRData',
      });
    });

    chartWidget.current = widget;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <div
        id="chartContainer"
        style={{
          flex: 1,
          border: '1px solid #333',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      ></div>
    </div>
  );
};

export default AdvancedMarketStateDashboard;




// import React, { useEffect, useRef } from 'react';
// import CustomDataFeed from './CustomDataFeed';
// import { customIndicatorsGetter } from './customIndicators'; // Import the custom indicator getter
// import { customIndicatorsGetter as volatilityIndicatorGetter } from './volatility_indicator'; // Import the volatility indicator getter
// import { customIndicatorsGetter as srIndicatorGetter } from './SR_indicator'; // Import the SR Levels Indicator getter
// import processSRData from './SR_series'; // Import the SR processing logic
// import useStore from '../Store/market_greeks_data_store'; // Import your Zustand store
//
// const AdvancedMarketStateDashboard = () => {
//   const spxData = useStore((state) => state.spxData); // Get SPX data from state
//   const spxGexData = useStore((state) => state.spxGexData); // Get SPX GEX data from state
//   const vvixData = useStore((state) => state.vvixData); // Get VVIX data from state
//   const topStrikesData = useStore((state) => state.topStrikes); // Get Top Strikes data from state
//   const setOriginalSRData = useStore((state) => state.setOriginalSRData); // Zustand setter for originalSRData
//   const originalSRData = useStore((state) => state.originalSRData); // Get Original SR Levels from state
//   const chartWidget = useRef(null); // Reference for the TradingView widget
//
//   // Process SR data whenever `topStrikesData` changes
//   useEffect(() => {
//     if (topStrikesData.length > 0) {
//       const { topCallStrikes, topPutStrikes } = processSRData(topStrikesData);
//       setOriginalSRData('topCallStrikes', topCallStrikes);
//       setOriginalSRData('topPutStrikes', topPutStrikes);
//     }
//   }, [topStrikesData, setOriginalSRData]); // React to changes in topStrikesData
//
//   useEffect(() => {
//     // Ensure we have data before attempting to load the chart
//     if (
//       spxData.length > 0 &&
//       spxGexData.length > 0 &&
//       vvixData.length > 0 &&
//       Object.keys(originalSRData).length > 0
//     ) {
//       loadChartWithIndicators(spxGexData, vvixData, originalSRData); // Pass all data to the chart
//     } else {
//       // console.warn('SPX data, SPX GEX data, VVIX data, or SR Levels data is not yet available.');
//     }
//
//     // Cleanup on component unmount
//     return () => {
//       if (chartWidget.current) {
//         const chart = chartWidget.current.activeChart();
//         chart.onVisibleRangeChanged().unsubscribeAll(this);
//         chart.crossHairMoved().unsubscribeAll(this);
//       }
//     };
//   }, [spxData, spxGexData, vvixData, originalSRData]); // React to updates in data
//
//   const loadChartWithIndicators = (spxGexData, vvixData, srLevelsData) => {
//     // console.log('SPX Data:', spxData);
//     // console.log('SPX GEX Data:', spxGexData);
//     // console.log('VVIX Data:', vvixData);
//     // console.log('SR Levels Data:', srLevelsData);
//
//     const dataFeed = new CustomDataFeed(); // Initialize custom data feed
//
//     // Create the TradingView widget
//     const widget = new window.TradingView.widget({
//       container: 'chartContainer', // The container ID for the chart
//       library_path: '/charting_library/', // Ensure this matches your project structure
//       datafeed: dataFeed,
//       symbol: 'SPX', // Main symbol
//       interval: '1D', // Timeframe
//       autosize: true, // Automatically resize
//       theme: 'Dark', // Apply dark theme
//       overrides: {
//         'paneProperties.background': '#070707',
//         'paneProperties.vertGridProperties.color': '#e9e9ea10',
//         'paneProperties.horzGridProperties.color': '#e9e9ea10',
//         'scalesProperties.textColor': '#FFFFFF',
//       },
//       disabled_features: [
//         'scroll_past_end', // Prevent scrolling past end
//         'create_volume_indicator_by_default', // Prevent default volume indicator
//       ],
//       custom_indicators_getter: async (PineJS) => {
//         const customIndicators = await customIndicatorsGetter(PineJS);
//         const volatilityIndicators = await volatilityIndicatorGetter(PineJS);
//         const srIndicators = await srIndicatorGetter(PineJS); // Add SR Levels Indicator
//         return [...customIndicators, ...volatilityIndicators, ...srIndicators]; // Combine all indicators
//       },
//     });
//
//     widget.onChartReady(() => {
//       const chart = widget.activeChart();
//
//       // Add the SPX Market GEX indicator
//       chart.createStudy('SPX Market GEX', false, false, {
//         offset: 0,
//         source: 'spxGexData', // Use the SPX GEX data for the indicator
//       });
//
//       // Add the SPX Gamma Volatility Indicator in a separate pane
//       chart.createStudy('SPX Gamma Volatility Indicator', false, false, {
//         offset: 0,
//         source: 'vvixData', // Use the VVIX data for the indicator
//       });
//
//       // Add the SR Levels Indicator with the originalSRData as the source
//       chart.createStudy('SR Levels Indicator', false, false, {
//         source: 'originalSRData', // Use the processed SR levels for the indicator
//       });
//
//       // console.log(
//       //   'Indicators added: SPX Market GEX, SPX Gamma Volatility Indicator, SR Levels Indicator.'
//       // );
//     });
//
//     chartWidget.current = widget; // Store widget reference
//   };
//
//   return (
//     <div style={{ width: '99%', height: '99%' }}>
//       <div
//         id="chartContainer"
//         style={{
//           width: '100%',
//           height: '100%',
//           border: '2px solid #333333',
//           borderRadius: '8px',
//         }}
//       ></div>
//     </div>
//   );
// };
//
// export default AdvancedMarketStateDashboard;





















































































