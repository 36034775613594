import useStore from '../Store/market_greeks_data_store';

export const customIndicatorsGetter = function (PineJS) {
  return Promise.resolve([
    {
      name: 'SPX Gamma Volatility Indicator',
      metainfo: {
        _metainfoVersion: 53,
        id: "SPXGammaVolatilityIndicator@tv-basicstudies-1",
        description: "SPX Gamma Volatility Indicator",
        shortDescription: "SPX Gamma Vol",
        format: { type: "inherit" },
        linkedToSeries: false,
        is_price_study: false,
        plots: [
          { id: "avg_z_score_plot", type: "line" },
          { id: "super_smooth_z_score_plot", type: "line" },
        ],
        defaults: {
          styles: {
            avg_z_score_plot: {
              linestyle: 0,
              linewidth: 2,
              plottype: 0, // Line plot
              trackPrice: false,
              transparency: 40, // 60% opacity
              visible: true,
              color: "#4CAF50", // Green line
            },
            super_smooth_z_score_plot: {
              linestyle: 0,
              linewidth: 2,
              plottype: 0, // Line plot
              trackPrice: false,
              transparency: 40, // 60% opacity
              visible: true,
              color: "#FF5722", // Orange line
            },
          },
        },
        styles: {
          avg_z_score_plot: { title: "VVIX Avg Z-Score", histogramBase: 0, joinPoints: true },
          super_smooth_z_score_plot: { title: "VVIX Super Smooth Z-Score", histogramBase: 0, joinPoints: true },
        },
        inputs: [], // No user input needed
      },
      constructor: function () {
        this.init = function (ctx) {
          this._context = ctx;

          // Fetch data from Zustand store
          const vvixData = useStore.getState().vvixData || [];
          if (!vvixData || vvixData.length === 0) {
            console.error('vvixData is undefined or empty.');
            this._vvixData = [];
            return;
          }

          // Map `vvixData` to PineJS-compatible format
          this._vvixData = vvixData
            .map(entry => {
              const avgZScore = parseFloat(entry.vvix_avg_z_score);
              const superSmoothZScore = parseFloat(entry.vvix_super_smooth_z_score);
              if (isNaN(avgZScore) || isNaN(superSmoothZScore)) {
                // console.warn(`Skipping entry with invalid scores:`, entry);
                return null; // Skip invalid entries
              }
              return {
                time: Math.floor(new Date(entry.date).getTime() / 1000), // Convert to Unix time in seconds
                avgZScore,
                superSmoothZScore,
              };
            })
            .filter(data => data !== null); // Exclude invalid entries

          // console.log('Mapped vvix data:', this._vvixData);
        };

        this.main = function (ctx) {
          const currentTime = Math.floor(ctx.symbol.time / 1000); // Convert to seconds

          if (!this._vvixData || this._vvixData.length === 0) {
            return [NaN, NaN]; // Return NaN if no data is available
          }

          // Find the closest matching `time` in `_vvixData`
          let closestMatch = null;
          let smallestDiff = Infinity;
          for (const data of this._vvixData) {
            const timeDiff = Math.abs(data.time - currentTime);
            if (timeDiff < smallestDiff) {
              closestMatch = data;
              smallestDiff = timeDiff;
            }
          }

          if (closestMatch) {
            // console.log(
            //   `Found closest VVIX values: avgZScore=${closestMatch.avgZScore}, superSmoothZScore=${closestMatch.superSmoothZScore} for time: ${closestMatch.time}`
            // );

            // Return the two plot values
            return [closestMatch.avgZScore, closestMatch.superSmoothZScore];
          }

          // console.warn(`No close match found for the current time (${currentTime}). Returning NaN.`);
          return [NaN, NaN]; // Return NaN if no match found
        };
      },
    },
  ]);
};
