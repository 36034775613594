import useStore from '../Store/market_greeks_data_store';

const processSRData = (topStrikes) => {
  // Find the most recent expiration date
  const mostRecentExpirationDate = Math.min(...topStrikes.map(item => new Date(item.expiration_date).getTime()));
  console.log('Most Recent Expiration Date:', new Date(mostRecentExpirationDate).toISOString());

  // Filter data for the most recent expiration date
  const filteredStrikesData = topStrikes.filter(
    item => new Date(item.expiration_date).getTime() === mostRecentExpirationDate
  );

  console.log('Filtered Strikes Data:', filteredStrikesData);

  // Separate call and put data
  const callData = filteredStrikesData.filter(item => item.type === 'call');
  const putData = filteredStrikesData.filter(item => item.type === 'put');

  // Sort call and put data by open interest
  const sortedCallData = callData.sort((a, b) => Number(b.open_interest) - Number(a.open_interest));
  const sortedPutData = putData.sort((a, b) => Number(b.open_interest) - Number(a.open_interest));

  // Select the top 3 strikes for both calls and puts
  const topCallStrikes = sortedCallData.slice(0, 3).map((item, index) => ({
    date: item.date, // Use the correct date field from the schema
    rank: index + 1,
    strike: item.strike_price,
    openInterest: item.open_interest,
    type: 'call',
  }));

  const topPutStrikes = sortedPutData.slice(0, 3).map((item, index) => ({
    date: item.date, // Use the correct date field from the schema
    rank: index + 1,
    strike: item.strike_price,
    openInterest: item.open_interest,
    type: 'put',
  }));

  console.log('Top 3 Call Strikes:', topCallStrikes);
  console.log('Top 3 Put Strikes:', topPutStrikes);

  // Update the Zustand store
  const setOriginalSRData = useStore.getState().setOriginalSRData;
  setOriginalSRData('topCallStrikes', topCallStrikes);
  setOriginalSRData('topPutStrikes', topPutStrikes);

  return {
    topCallStrikes,
    topPutStrikes,
  };
};

export default processSRData;
