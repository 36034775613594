import React, { useEffect, useState } from 'react';
import MarketGreeksWssClient from './Components/market_greeks_wss_client';
import MarketStateChart from './Components/market_state';
import { useMemberstack } from "@memberstack/react";  // Import the useMemberstack hook

const allowedPlanIds = [
  'prc_legacy-membership-bg54063e',  
  'prc_free-trial-legacy-membership-vi20zyi', 
  'pln_vip-membership-c0ea0lhm',
  'pln_72-hour-free-trial-k8d606hc'
];

function App() {
  const [loadingMember, setLoadingMember] = useState(true);  // Track member loading state
  const [member, setMember] = useState(null);  // Store member information
  const memberstack = useMemberstack();

  // Fetch the current member and check their plan
  useEffect(() => {
    memberstack.getCurrentMember()
      .then(({ data: member }) => {
        if (!member) {
          // If no member is found, redirect to the denied page
          window.location.href = 'https://www.spxgamma.com/denied';
        } else {
          // Check if the member's plan is allowed
          const planConnections = member.planConnections;
          const hasValidPlan = planConnections?.some(plan => {
            // Use plan.planId for free plans, plan.payment.priceId for paid plans
            return allowedPlanIds.includes(plan.payment?.priceId || plan.planId);
          });

          if (hasValidPlan) {
            setMember(member);  // Set the member info
            setLoadingMember(false);  // Stop loading
            console.log("Current member with valid plan:", member);
          } else {
            // Redirect if the member doesn't have a valid plan
            window.location.href = 'https://www.spxgamma.com/denied';
          }
        }
      })
      .catch(err => {
        console.error("Error fetching current member:", err);
        window.location.href = 'https://www.spxgamma.com/denied';  // Redirect on error
      });
  }, [memberstack]);

  // While member info is loading, show a loading message
  if (loadingMember) {
    return <div>Loading...</div>;  // Display a loading screen while checking membership
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="app-container">
          <MarketGreeksWssClient /> {/* This will initiate the WebSocket connection */}
          <MarketStateChart /> {/* This will render the chart */}
        </div>
      </header>
    </div>
  );
}

export default App;








