import { useEffect } from 'react';
import useStore from '../Store/market_greeks_data_store';

// const MarketGreeksWssClient = () => {
//   useEffect(() => {
//     // console.log('Initializing WebSocket connection...');
//     const ws = new WebSocket('ws://localhost:3002');

//     ws.onopen = () => {
//       console.log('Connected to WebSocket server');
//       // Request market state data
//       ws.send(JSON.stringify({ type: 'market-state-data' }));
//     };

//Websocket server code for heroku.
const MarketGreeksWssClient = () => {
  useEffect(() => {
    // Access the WebSocket URL from the environment variable
    const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3002'; // Fallback for local development

    // Initialize WebSocket connection
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      // Request market state data
      ws.send(JSON.stringify({ type: 'market-state-data' }));
    };

    ws.onmessage = (event) => {
      // console.log('WebSocket message received');
      const response = JSON.parse(event.data);

      if (response.type === 'market-state-data-response') {
        const { setSpxData, setVixData, setMarketStatus, setTopStrikes, setVvixData, setSpxGexData } = useStore.getState();

        // Function to format the date correctly
        const transformDate = (date) => {
          // console.log('Original date:', date); // Log the original date

          if (typeof date === 'string') {
            // Convert ISO string (e.g., "2024-08-07T07:00:00.000Z") to YYYY-MM-DD format
            const transformedDate = date.split('T')[0];
            // console.log('Transformed date:', transformedDate);
            return transformedDate;
          } else if (typeof date === 'number') {
            // If date is a Unix timestamp, convert it to YYYY-MM-DD
            const transformedDate = new Date(date * 1000).toISOString().split('T')[0];
            // console.log('Transformed date from timestamp:', transformedDate);
            return transformedDate;
          } else if (date && date.year && date.month && date.day) {
            // If date is an object with year, month, and day
            const transformedDate = `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
            // console.log('Transformed date from object:', transformedDate);
            return transformedDate;
          } else {
            throw new Error('Invalid date format');
          }
        };

        const formatDataDate = (data) => {
          return data.map(item => ({
            ...item,
            date: transformDate(item.date), // Format the date field
          }));
        };

        const sortDataChronologically = (data) => {
          return data.sort((a, b) => new Date(b.date) - new Date(a.date));
        };

        // Format and sort each dataset before updating the Zustand store
        setSpxData(sortDataChronologically(formatDataDate(response.spxData)));
        setVixData(sortDataChronologically(formatDataDate(response.vixData)));
        setMarketStatus(sortDataChronologically(formatDataDate(response.marketStatus)));
        setTopStrikes(sortDataChronologically(formatDataDate(response.topStrikes)));
        setVvixData(sortDataChronologically(formatDataDate(response.vvixData)));
        setSpxGexData(sortDataChronologically(formatDataDate(response.spxGexData)));
      }
    };

    ws.onclose = (event) => {
      // console.log(`WebSocket connection closed: code=${event.code}, reason=${event.reason}`);
    };

    ws.onerror = (error) => {
      // console.error('WebSocket error:', error);
    };

    // Clean up the WebSocket connection when the component is unmounted
    return () => {
      // console.log('Cleaning up WebSocket connection...');
      ws.close();
    };
  }, []);

  return null; // This component does not render anything
};

export default MarketGreeksWssClient;












