import { LineStyle } from 'lightweight-charts'; // Correct import for lightweight-charts

const SERIES = [
  // Candlestick series
  {
    key: 'candlestick',
    type: 'candlestick',
    scale: 0,
    buttonLabel: 'Candles',
    chartOptions: {
      upColor: '#B18800',      // Up candle color
      downColor: '#838ca1',    // Down candle color
      borderUpColor: '#B18800',// Border color for up candles
      borderDownColor: '#838ca1', // Border color for down candles
      wickUpColor: '#838ca1',  // Wick color for up candles
      wickDownColor: '#838ca1', // Wick color for down candles
      priceLineVisible: false,
    }
  },
  // Market GEX series
  {
    key: 'market_gex',
    type: 'baseline',
    scale: 3,
    buttonLabel: 'Market GEX',
    chartOptions: {
      priceLineVisible: false,
      baseValue: {
        type: 'price',
        price: 0,
      },
      topFillColor1: 'rgba(0, 255, 0, 0.7)',
      topFillColor2: 'rgba(0, 255, 0, 0.3)',
      topLineColor: 'rgba(0, 255, 0, 1)',
      bottomFillColor1: 'rgba(255, 0, 0, 0.7)',
      bottomFillColor2: 'rgba(255, 0, 0, 0.3)',
      bottomLineColor: 'rgba(255, 0, 0, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      crossHairMarkerVisible: true,
      crossHairMarkerRadius: 4,
    },
  },
{
  key: 'vvix_avg_z_score',
  type: 'baseline',
  scale: 4,
  buttonLabel: 'VVIX Avg Z-Score',
  chartOptions: {
    baseValue: { type: 'price', price: 0 },  // Set the baseline at zero
    topLineColor: '#FF4500', // Bright orange-red line color for intensity
    bottomLineColor: '#FFD700', // Bright gold line color for vivid contrast
    lineWidth: 1,  // Thicker line for emphasis
    lineStyle: LineStyle.Solid,
    priceLineVisible: false,
    topFillColor1: 'rgba(255, 69, 0, 0.9)', // Gradient starting with bright orange-red
    topFillColor2: 'rgba(255, 215, 0, 0.7)', // Gradient to bright gold
    bottomFillColor1: 'rgba(255, 165, 0, 0.8)', // Gradient starting with bright orange
    bottomFillColor2: 'rgba(255, 140, 0, 0.6)', // Gradient to darker orange for warmth
  }
},
{
  key: 'vvix_super_smooth_z_score',
  type: 'baseline',
  scale: 4,
  buttonLabel: 'VVIX Super Smooth Z-Score',
  chartOptions: {
    baseValue: { type: 'price', price: 0 },  // Set the baseline at zero
    topLineColor: '#00FFFF', // Bright cyan line color for maximum brightness
    bottomLineColor: '#FF00FF', // Neon magenta line color for a striking contrast
    lineWidth: 1,  // Thicker line for emphasis
    lineStyle: LineStyle.Solid,
    priceLineVisible: false,
    topFillColor1: 'rgba(0, 255, 255, 0.9)', // Gradient starting with bright cyan
    topFillColor2: 'rgba(255, 0, 255, 0.7)', // Gradient to neon magenta
    bottomFillColor1: 'rgba(75, 0, 130, 0.8)', // Gradient starting with indigo for depth
    bottomFillColor2: 'rgba(238, 130, 238, 0.6)', // Gradient to vivid violet
  }
},

  // Histogram for VVIX Avg Z-Score minus VVIX Super Smooth Z-Score
  {
    key: 'vvix_z_score_diff',
    type: 'histogram',
    scale: 5,
    buttonLabel: 'VVIX Z-Score Diff',
    chartOptions: {
      color: 'rgba(0, 128, 0, 0.7)', // Default color for histogram bars
      priceFormat: {
        type: 'custom',
        formatter: (price) => {
          return price >= 0 ? `+${price}` : `${price}`;
        }
      },
    },
  },
];

export default SERIES;


