const { create } = require('zustand');

const useStore = create((set) => ({
  spxData: [],
  vixData: [],
  marketStatus: [],
  topStrikes: [],
  vvixData: [],
  spxGexData: [],
  originalSRData: {},  // Store SR line data
  showSrLines: true,   // Track visibility of SR lines
  currentData: 'spx',  // Track currently displayed data

  setSpxData: (data) => set({ spxData: data }),
  setVixData: (data) => set({ vixData: data }),
  setMarketStatus: (data) => set({ marketStatus: data }),
  setTopStrikes: (data) => set({ topStrikes: data }),
  setVvixData: (data) => set({ vvixData: data }),
  setSpxGexData: (data) => set({ spxGexData: data }),

  // Setter for original SR line data
  setOriginalSRData: (key, data) => set((state) => ({
    originalSRData: {
      ...state.originalSRData,
      [key]: data,
    },
  })),


  // Toggle between SPX and VIX data
  toggleData: (data) => set((state) => {
    const showSrLines = data === 'spx'; // Only show SR lines when SPX is selected
    return { currentData: data, showSrLines };
  }),
}));

module.exports = useStore;






