// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background-color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Main App Container */
.App {
  min-height: 100vh;
  display: flex;
  align-items: flex-start; /* Align items to the top */
  justify-content: center;
  padding-top: 20px; /* Optional: Add some padding from the top */
}

/* Header Styling */
.App-header {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  width: 100%;
}

/* Chart Container */
.app-container {
  width: 100%;
  display: flex;
  align-items: flex-start; /* Align chart to the top */
  justify-content: center;
}



`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,yBAAyB;EACzB;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,uBAAuB;AACvB;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB,EAAE,2BAA2B;EACpD,uBAAuB;EACvB,iBAAiB,EAAE,4CAA4C;AACjE;;AAEA,mBAAmB;AACnB;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B,EAAE,2BAA2B;EACxD,WAAW;AACb;;AAEA,oBAAoB;AACpB;EACE,WAAW;EACX,aAAa;EACb,uBAAuB,EAAE,2BAA2B;EACpD,uBAAuB;AACzB","sourcesContent":["body {\n  margin: 0;\n  background-color: #000000;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* Main App Container */\n.App {\n  min-height: 100vh;\n  display: flex;\n  align-items: flex-start; /* Align items to the top */\n  justify-content: center;\n  padding-top: 20px; /* Optional: Add some padding from the top */\n}\n\n/* Header Styling */\n.App-header {\n  background-color: #000000;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start; /* Align items to the top */\n  width: 100%;\n}\n\n/* Chart Container */\n.app-container {\n  width: 100%;\n  display: flex;\n  align-items: flex-start; /* Align chart to the top */\n  justify-content: center;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
