// App.js
import React, { useState, useEffect } from 'react'; // Ensure useState and useEffect are imported
import './App.css';
import ADVANCED_MARKET_STATE_DASHBOARD from './Components/AdvancedMarketStateDashboard.js'; // Exact file name
import MarketGreeksWssClient from './Components/MarketStateWebsocketClient.js'; // Exact file name
import { useMemberstack } from "@memberstack/react"; // Import the useMemberstack hook

const allowedPlanIds = [
  'prc_legacy-membership-bg54063e',
  'prc_free-trial-legacy-membership-vi20zyi',
  'pln_vip-membership-c0ea0lhm',
  // 'pln_72-hour-free-trial-k8d606hc'
];

function App() {
  const [loadingMember, setLoadingMember] = useState(true);  // Track member loading state
  const [member, setMember] = useState(null);  // Store member information
  const memberstack = useMemberstack();

  // Fetch the current member and check their plan
  useEffect(() => {
    memberstack.getCurrentMember()
      .then(({ data: member }) => {
        if (!member) {
          // If no member is found, redirect to the denied page
          window.location.href = 'https://www.spxgamma.com/denied';
        } else {
          // Check if the member's plan is allowed and active or trialing
          const planConnections = member.planConnections;
          const hasValidPlan = planConnections?.some(plan => {
            const planId = plan.payment?.priceId || plan.planId; // Handle both paid and free plans
            return (
              (plan.status === 'ACTIVE' || plan.status === 'TRIALING') &&
              allowedPlanIds.includes(planId) // Require active or trialing status
            );
          });
  
          if (hasValidPlan) {
            setMember(member);  // Set the member info
            setLoadingMember(false);  // Stop loading
            console.log("Current member with valid plan (ACTIVE or TRIALING):", member);
          } else {
            // Redirect if the member doesn't have a valid plan
            window.location.href = 'https://www.spxgamma.com/denied';
          }
        }
      })
      .catch(err => {
        console.error("Error fetching current member:", err);
        window.location.href = 'https://www.spxgamma.com/denied';  // Redirect on error
      });
  }, [memberstack]);
  
  // While member info is loading, show a loading message
  if (loadingMember) {
    return <div>Loading...</div>;  // Display a loading screen while checking membership
  }

  // Render the main application if the member is valid
  return (
    <div className="App">
      <MarketGreeksWssClient /> {/* Initiates WebSocket connection */}
      <ADVANCED_MARKET_STATE_DASHBOARD />
    </div>
  );
}

export default App;






